const config = {
  rootProjectId: "root",
  uiBucketName: "prod.c.retter.io",
  appUrl: "https://api.prod.retter.io/",
  cosUrl: "api.prod.retter.io",
  version: "2.1.0",
  captchaKey: "6LeeM0spAAAAAH1mXka_QbmnLwZN9ttX46oJyoa1",
  stage: "PROD"
}
export default config

